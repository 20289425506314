<template>
  <div
    v-if="appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader"
    class="v-d-flex v-flex-column"
  >
    <cart-order-type
      is-in-header
      :as-select="!isDesktopVersion || moreThanTwo"
      :order-type-show-icons="
        appConfig.VueSettingsPreRun.OrderTypeShowIconsInCart && !moreThanTwo
      "
    />
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="clientStore.averageTimeWithDelayMs > 0"
        class="v-average-delivery-caption--umbar v-text-center v-align-items-center"
      >
        <span
          v-if="clientStore.ClientState.data?.OrderType === OrderType.InHall"
          v-html="
            translate(
              isSmall ? 'averageTime.captionHallMobile' : 'averageTime.captionHall'
            )
          "
        />
        <span
          v-else-if="
            clientStore.ClientState.data?.OrderType === OrderType.NoShipment
          "
          v-html="
            translate(
              isSmall
                ? 'averageTime.captionPickupMobile'
                : 'averageTime.captionPickup'
            )
          "
        />
        <span
          v-else
          v-html="
            translate(
              isSmall
                ? 'averageTime.captionDeliveryMobile'
                : 'averageTime.captionDelivery'
            )
          "
        />
        <header-parts-average-time-base />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

import { OrderType } from '~api/consts'

const { translate } = useI18nSanitized()
const { isDesktopVersion, isSmall } = useWindowSize()
const addressStore = useAddressStore()

onMounted(async () => {
  await addressStore.initDeliveryAndPay()
})

const appConfig = useAppConfig()
const clientStore = useClientStore()

const moreThanTwo = computed<boolean>(() => {
  if (addressStore.DeliveryAndPay?.data) {
    return (
      addressStore.DeliveryAndPay.data.EnableSelfService &&
      addressStore.DeliveryAndPay.data.EnableDeliveryCourier &&
      addressStore.DeliveryAndPay.data.EnableInHall
    )
  }

  return false
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-average-delivery-caption--umbar {
  cursor: default;
  color: variables.$HeaderColor;
  opacity: 0.8;
  font-size: variables.$TextSizeMain;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;

  @include mixins.lg {
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
